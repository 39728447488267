import { OPERATOR_SORT, OPERATOR_TYPE } from '@features/filters/types';
import { getCurrentDateStartEndTime } from '@utils/getCurrentDateStartEndTime';

export const getInitialSearchParams = (timezone?: string) => ({
  page: 1,
  size: 10,
  sorts: [
    {
      field: 'campaign_name',
      order: OPERATOR_SORT.DESC,
    },
  ],
  filters: timezone
    ? [
        {
          value: true,
          inversion: false,
          field: 'enabled',
          type: OPERATOR_TYPE.EQUAL,
        },
        {
          inversion: false,
          field: 'given_at',
          type: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
          value: getCurrentDateStartEndTime('start', timezone).valueOf(),
        },
        {
          inversion: false,
          field: 'given_at',
          type: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
          value: getCurrentDateStartEndTime('end', timezone).valueOf(),
        },
      ]
    : [],
});
