import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IInitialFilter,
  type ISearchParams,
} from '@features/filters/types';
import { getInitialSearchParams } from '@modules/campaignActivityReport/utils/getInitialSearchParams';

interface IInitialState {
  searchParams: ISearchParams;
  expandedRows: Record<string, boolean>;
  prevFilters: Record<string, IInitialFilter>;
}

const initialState: IInitialState = {
  prevFilters: {},
  expandedRows: {},
  searchParams: getInitialSearchParams(),
};

const campaignActivitySlice = createSlice({
  initialState,
  name: 'campaignActivitySlice',
  reducers: {
    resetExpandedRows(state: IInitialState) {
      state.expandedRows = initialState.expandedRows;
    },
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: IInitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    addExpandedRow(
      state: IInitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = { ...state.expandedRows, ...action.payload };
    },
    updateExpandedRow(
      state: IInitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = {
        ...state.expandedRows,
        [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
      };
    },
  },
});

export default campaignActivitySlice.reducer;

export const {
  addExpandedRow,
  setPrevFilters,
  setSearchParams,
  updateExpandedRow,
  resetExpandedRows,
} = campaignActivitySlice.actions;
