import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IFilterChip,
  type ISearchParams,
  OPERATOR_SORT,
} from '@features/filters/types';
import { filtersInitialValues } from '@modules/playerCodes/submodules/TableFilters/constants';
import { IFilterItem, IFiltersInitialValues } from '@modules/playerCodes/types';

interface IInitialState {
  chipList: IFilterChip[];
  gridQueryParams: string;
  filterList: IFilterItem[];
  searchParams: ISearchParams;
  activePlayerCodeId: null | string;
  prevFilters: IFiltersInitialValues;
}

export const initialPlayerCodesSearchParams = {
  page: 1,
  size: 10,
  filters: [],
  sorts: [
    {
      field: 'applied_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: IInitialState = {
  chipList: [],
  filterList: [],
  gridQueryParams: '',
  activePlayerCodeId: null,
  prevFilters: filtersInitialValues,
  searchParams: initialPlayerCodesSearchParams,
};

const playerCodesSlice = createSlice({
  initialState,
  name: 'playerCodesSlice',
  reducers: {
    resetChipList(state: IInitialState) {
      state.chipList = initialState.chipList;
    },
    setChipList(state: IInitialState, action: PayloadAction<IFilterChip[]>) {
      state.chipList = action.payload;
    },
    setFilterList(state: IInitialState, action: PayloadAction<IFilterItem[]>) {
      state.filterList = action.payload;
    },
    addChipItem(state: IInitialState, action: PayloadAction<IFilterChip>) {
      state.chipList = [...state.chipList, action.payload];
    },
    addFilter(state: IInitialState, action: PayloadAction<IFilterItem>) {
      state.filterList = [...state.filterList, action.payload];
    },
    setSearchParams(
      state: IInitialState,
      action: PayloadAction<ISearchParams>
    ) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: IInitialState,
      action: PayloadAction<IInitialState['prevFilters']>
    ) {
      state.prevFilters = action.payload;
    },
    setGridQueryParams(
      state: IInitialState,
      action: PayloadAction<IInitialState['gridQueryParams']>
    ) {
      state.gridQueryParams = action.payload;
    },
    setActivePlayerCodeId(
      state: IInitialState,
      action: PayloadAction<IInitialState['activePlayerCodeId']>
    ) {
      state.activePlayerCodeId = action.payload;
    },
    removeChipItem(state: IInitialState, action: PayloadAction<string>) {
      state.chipList = state.chipList.filter(
        (item) => item.field !== action.payload
      );
    },
    removeFilter(state: IInitialState, action: PayloadAction<string>) {
      state.filterList = state.filterList.filter(
        (item) => item.value !== action.payload
      );
    },
  },
});

export default playerCodesSlice.reducer;

export const {
  addFilter,
  setChipList,
  addChipItem,
  removeFilter,
  setFilterList,
  resetChipList,
  removeChipItem,
  setPrevFilters,
  setSearchParams,
  setGridQueryParams,
  setActivePlayerCodeId,
} = playerCodesSlice.actions;
