import {
  type BaseQueryApi,
  createApi,
  type FetchArgs,
} from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';

import { IExportMutationParams, IExportResponse } from '@api/types';
import { API, ENV } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import {
  type ICancelPlayerCodeParams,
  IPlayerCodeDetails,
  type IPlayerCodesSearch,
} from '@modules/playerCodes/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { catchCommonError } from '@utils/catchCommonError';
import { exportFileResponseHandler } from '@utils/exportFileResponseHandler';
import replaceURLParams from '@utils/replaceURLParams';

interface IExtraOptions {
  useBonusService?: boolean;
}

export const playerCodesReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerCodesReportingService',
  baseQuery: (
    args: FetchArgs,
    api: BaseQueryApi,
    extraOptions: IExtraOptions
  ) => {
    const service = extraOptions?.useBonusService
      ? ENV.VITE_BONUS_API_URL
      : ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL;

    return baseQueryCreator(service)(args, api, extraOptions);
  },
});

export const playerCodesApi = playerCodesReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-codes-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerCodesList: builder.query<IPlayerCodesSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_CODES.GRID,
        }),
      }),
      getPlayerCodeDetails: builder.query<IPlayerCodeDetails, string>({
        query: (id) => ({
          method: 'GET',
          url: replaceURLParams(API.PLAYER_CODES.CODE.DETAILS, {
            id,
          }),
        }),
      }),
      exportPlayerCodes: builder.mutation<
        IExportResponse,
        IExportMutationParams
      >({
        query: ({ timeZone, searchParams }) => {
          const url = replaceURLParams(
            API.PLAYER_CODES.EXPORT,
            {
              timeZone,
            },
            {
              asQueryParam: true,
            }
          );

          return {
            url,
            method: 'POST',
            body: searchParams,
            responseHandler: exportFileResponseHandler,
          };
        },
      }),
      cancelPlayerCode: builder.mutation<unknown, ICancelPlayerCodeParams>({
        extraOptions: { useBonusService: true },
        query: ({ id }) => ({
          method: 'POST',
          url: replaceURLParams(API.PLAYER_CODES.CODE.CANCEL, {
            id,
          }),
        }),
        async onQueryStarted(
          { id, userName, searchParams },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;

            dispatch(
              playerCodesApi.util.updateQueryData(
                'getPlayerCodesList',
                searchParams,
                (response) => {
                  const updatedOffersList = response.data.map((row) =>
                    row.id === id
                      ? {
                          ...row,
                          status: 'CANCELLED',
                          cancelledBy: userName,
                          cancelledAt: dayjs().valueOf(),
                        }
                      : row
                  );

                  return { ...response, data: updatedOffersList };
                }
              )
            );
          } catch (e) {
            catchCommonError(e);
          }
        },
      }),
    }),
  });

export const {
  useCancelPlayerCodeMutation,
  useExportPlayerCodesMutation,
  useLazyGetPlayerCodesListQuery,
  useLazyGetPlayerCodeDetailsQuery,
} = playerCodesApi;
