import {
  type BaseQueryApi,
  createApi,
  type FetchArgs,
} from '@reduxjs/toolkit/query/react';

import { IExportMutationParams, IExportResponse } from '@api/types';
import { API, ENV } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import {
  type IPlayerOfferDetails,
  type IPlayerOffersSearch,
} from '@modules/playerOffers/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { catchCommonError } from '@utils/catchCommonError';
import { exportFileResponseHandler } from '@utils/exportFileResponseHandler';
import replaceURLParams from '@utils/replaceURLParams';

interface IExtraOptions {
  useBonusService?: boolean;
}

interface ICancelPlayerOfferParams {
  id: string;
  searchParams: ISearchParams;
}

export const playerOffersServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerOffersService',
  baseQuery: (
    args: FetchArgs,
    api: BaseQueryApi,
    extraOptions: IExtraOptions
  ) => {
    const service = extraOptions?.useBonusService
      ? ENV.VITE_BONUS_API_URL
      : ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL;

    return baseQueryCreator(service)(args, api, extraOptions);
  },
});

export const playerOffersApi = playerOffersServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-offers-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerOfferDetails: builder.query<IPlayerOfferDetails, string>({
        query: (id) => ({
          method: 'GET',
          url: replaceURLParams(API.PLAYER_OFFERS.OFFER.DETAILS, {
            id,
          }),
        }),
      }),
      getPlayerOffersList: builder.query<IPlayerOffersSearch, ISearchParams>({
        providesTags: ['player-offers-list'],
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_OFFERS.GRID,
        }),
      }),
      exportPlayerOffersData: builder.mutation<
        IExportResponse,
        IExportMutationParams
      >({
        query: ({ timeZone, searchParams }) => {
          const url = replaceURLParams(
            API.PLAYER_OFFERS.EXPORT,
            {
              timeZone,
            },
            {
              asQueryParam: true,
            }
          );

          return {
            url,
            method: 'POST',
            body: searchParams,
            responseHandler: exportFileResponseHandler,
          };
        },
      }),
      cancelPlayerOffer: builder.mutation<unknown, ICancelPlayerOfferParams>({
        extraOptions: { useBonusService: true },
        query: ({ id }) => ({
          method: 'POST',
          url: replaceURLParams(API.PLAYER_OFFERS.OFFER.CANCEL, {
            id,
          }),
        }),
        async onQueryStarted(
          { id, searchParams },
          { dispatch, queryFulfilled }
        ) {
          try {
            await queryFulfilled;

            dispatch(
              playerOffersApi.util.updateQueryData(
                'getPlayerOffersList',
                searchParams,
                (response) => {
                  const updatedOffersList = response.data.map((row) =>
                    row.id === id ? { ...row, status: 'CANCELLING' } : row
                  );

                  return { ...response, data: updatedOffersList };
                }
              )
            );
          } catch (e) {
            catchCommonError(e);
          }
        },
      }),
    }),
  });

export const {
  useCancelPlayerOfferMutation,
  useLazyGetPlayerOffersListQuery,
  useLazyGetPlayerOfferDetailsQuery,
  useExportPlayerOffersDataMutation,
} = playerOffersApi;
