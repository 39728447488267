import { createApi } from '@reduxjs/toolkit/query/react';

import { IExportMutationParams, IExportResponse } from '@api/types';
import { API, ENV } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import { type IPlayerBonusesSearch } from '@modules/playerBonuses/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';
import { exportFileResponseHandler } from '@utils/exportFileResponseHandler';
import replaceURLParams from '@utils/replaceURLParams';

export const playerReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'playerReportingService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL)(...args),
});
export const playerBonusesApi = playerReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['player-bonuses-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPlayerBonusesList: builder.query<IPlayerBonusesSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.PLAYER_BONUSES_LIST.GRID,
        }),
      }),
      exportPlayerBonusesData: builder.mutation<
        IExportResponse,
        IExportMutationParams
      >({
        query: ({ timeZone, searchParams }) => {
          const url = replaceURLParams(
            API.PLAYER_BONUSES_LIST.EXPORT,
            {
              timeZone,
            },
            { asQueryParam: true }
          );

          return {
            url,
            method: 'POST',
            body: searchParams,
            responseHandler: exportFileResponseHandler,
          };
        },
      }),
    }),
  });

export const {
  useLazyGetPlayerBonusesListQuery,
  useExportPlayerBonusesDataMutation,
} = playerBonusesApi;
